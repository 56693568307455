* {
  font-family: "Roboto", sans-serif;
}

.carousel-container {
  height: 360px;
}

.carousel-item {
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.carousel-item-next {
  transform: translateX(100%);
}

.carousel-item-prev {
  transform: translateX(-100%);
}

.carousel-item-active {
  transform: translateX(0%);
}

.banner-seo-check .count {
  padding: 50px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
}
.banner-seo-check .count h4 {
  color: #fff;
  font-size: 16px;
  margin-top: 0;
}
.banner-seo-check .count .fa {
  font-size: 40px;
  display: block;
  color: #fff;
}
.banner-seo-check .number {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}
/* navbar */
.navbar .Logo {
  width: 30%;
}
.navbar-scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* name */
.nameCon img {
  object-fit: cover;
  height: 300px;
}

/* home */

.navbar-dark .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
  color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary);
  text-decoration: none;
}

.navbar-dark .navbar-brand h1 {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
    position: relative;
    background: #ffffff;
  }

  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    color: var(--dark);
  }

  .navbar-dark .navbar-brand h1 {
    color: var(--primary);
  }
}

@media (min-width: 992px) {
  .navbar-dark {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(256, 256, 256, 0.1);
    z-index: 999;
  }

  .sticky-top.navbar-dark {
    position: fixed;
    background: #ffffff;
  }

  .navbar-dark .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    bottom: -1px;
    left: 50%;
    /* background: var(--primary); */
    transition: 0.5s;
  }

  .navbar-dark .navbar-nav .nav-link:hover::before,
  .navbar-dark .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }

  .navbar-dark .navbar-nav .nav-link.nav-contact::before {
    display: none;
  }

  .sticky-top.navbar-dark .navbar-brand h1 {
    color: var(--primary);
  }
}

/*** Carousel ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(9, 30, 62, 0.7);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.home .heading1 {
  font-weight: 600;
  color: #145b84;
}
.home .testimonial {
  min-height: 65vh;
}
/* services */
.services .technologies-img {
  width: 2rem;
  height: 2rem;
}

/* careers */
.careers .head-img {
  height: 400px;
  width: 400px;
}

/* about */
.about .phoneLogo {
  width: 60px;
  height: 60px;
}
.about .teamCon {
  height: 350px;
}
.about .teamCon img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

/* contact */
.contact .contactCon h4 {
  font-size: 15px;
}
.contact .icon {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 600px) {
  .navbar .Logo {
    width: 70%;
  }
  .navbar .navbar-nav .nav-link {
    font-size: 15px;
  }
  .footer .footerCon h5 {
    font-size: 20px;
  }
  .footer .footerCon a {
    font-size: 15px;
  }

  .home .homeCon h5 {
    font-size: 10px;
  }
  .home .homeCon h1 {
    font-size: 15px;
  }
  .home .homeCon a {
    font-size: 8px;
  }
  .home .homeCon .carousel-control-prev-icon,
  .home .homeCon .carousel-control-next-icon {
    width: 1.2rem;
    height: 1.2rem;
  }
  .home .heading1 {
    font-size: 25px;
  }
  .home .whyChooseUs h2 {
    font-size: 25px;
  }
  .home .client h2 {
    font-size: 25px;
  }
  .home .testimonial {
    height: 100%;
  }
  .home .infoCover h2 {
    font-size: 25px;
  }
  .home .infoCover p {
    font-size: 15px;
  }
  .services .serviceCon h2 {
    font-size: 25px;
  }
  .services .serviceCon h5 {
    font-size: 18px;
  }
  .services .servicesFeature h2 {
    font-size: 20px;
  }
  .services .serviceTab h2 {
    font-size: 20px;
  }
  .services .serviceTab p {
    font-size: 15px;
    text-align: justify;
  }
  .careers .careersCon h5 {
    font-size: 15px;
  }
  .careers .careersCon h2 {
    font-size: 25px;
  }
  .careers .careersCon p {
    font-size: 15px;
    text-align: justify;
  }
  .careers .careersCon li {
    font-size: 12px;
  }
  .careers .careersAcordian p {
    font-size: 12px;
    text-align: start;
  }
  .careers .head-img {
    height: 200px;
    width: 200px;
  }
  .about .aboutCon h2 {
    font-size: 20px;
  }
  .about .aboutCon p {
    font-size: 15px;
    text-align: justify;
  }
  .about .aboutCon h5 {
    font-size: 15px;
    text-align: justify;
  }
  .about .aboutCon h4 {
    font-size: 15px;
    text-align: justify;
  }
  .about .phoneLogo {
    width: 30px;
    height: 30px;
  }
  .about .aboutCon2 h2 {
    font-size: 20px;
  }
  .about .aboutCon2 p {
    font-size: 15px;
    text-align: justify;
  }
  .about .aboutCon3 h2 {
    font-size: 20px;
  }
  .about .aboutCon3 p {
    font-size: 15px;
    text-align: justify;
  }
  .about .teamCon img {
    width: 150px;
    height: 150px;
  }
  .contact .contactCon h2 {
    font-size: 20px;
  }
  .contact .contactCon h5 {
    font-size: 15px;
  }

  .contact .icon {
    width: 30px;
    height: 30px;
  }
}

.btn-outline-light:hover {
  filter: blur(0.7px);
}
